<template>
    <div>
        <v-card outlined v-show="showMonitor" class="v-card-border-none">
            <v-card-title class="pt-2 pb-2 v-card-custom-title">
                <div
                    class="text-left v-card-custom-title-left"
                    style="width: 35%"
                >
                    <template v-if="!VUETIFY_BREAKPOINT.mobile">
                        <span style="white-space: nowrap"
                            >Mandate Monitor<v-tooltip
                                right
                                v-if="configWarning"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        color="red"
                                        small
                                        style="margin-left: 2px"
                                        >mdi-information-outline</v-icon
                                    >
                                </template>
                                <span
                                    >No config for Mandate Monitor can be
                                    found.</span
                                >
                            </v-tooltip></span
                        >
                    </template>
                </div>
                <div
                    class="text-right v-card-custom-title-right"
                    style="width: 65%"
                >
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                x-small
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                                {{ selectedAmount }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="(item, index) in amountOption"
                                :key="index"
                                dense
                                @click="selectedAmount = item"
                            >
                                <v-list-item-title>{{
                                    item
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    {{ " " }}
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                x-small
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                                {{ selectedChartOption }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="(item, index) in chartOption"
                                :key="index"
                                dense
                                @click="selectedChartOption = item"
                            >
                                <v-list-item-title>{{
                                    item
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    {{ " " }}
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                x-small
                                color="primary"
                                dark
                                v-bind="attrs"
                                class="alertBookSelection"
                                v-on="on"
                            >
                                {{ currentBook }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="(item, index) in filteredBookOptions"
                                :key="index"
                                dense
                                @click="onSelectBook(item)"
                            >
                                <v-list-item-title>{{
                                    item
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    {{ " " }}
                    <v-icon
                        text
                        small
                        color="default"
                        dark
                        @click="toggleMonitorSortingOrder"
                    >
                        {{
                            this.monitorChartAsc
                                ? "mdi-sort-ascending"
                                : "mdi-sort-descending"
                        }}
                    </v-icon>
                    <template v-if="!VUETIFY_BREAKPOINT.mobile">
                        {{ " " }}
                        <v-icon
                            text
                            small
                            color="blue-grey darken-1"
                            v-on:click="getConfigForAlertAction('Mandate')"
                            v-if="showConfig"
                            class="alertConfig"
                            >mdi-cog</v-icon
                        >
                        {{ " " }}
                        <v-icon
                            small
                            style="color: grey"
                            @click="$emit('onRemove')"
                            class="alertClose"
                            >mdi-close-thick</v-icon
                        >
                    </template>
                </div>
            </v-card-title>

            <ChartMandate
                :isAscending="monitorChartAsc"
                :chartOption="selectedChartOption"
                :amountOption="selectedAmount"
                :height="height"
            />
        </v-card>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { helper } from "@components/mixins/helper";
import common from "@assets/js/common";
import ChartMandate from "./chart/ComponentMandateChart";
import { getConfig } from "@services/alert/config";

export default {
    mixins: [helper],
    components: { ChartMandate },
    props: {
        height: 0,
        showConfig: false,
    },
    data() {
        return {
            showAlert: false,
            showMonitor: true,
            selctedBrokerId: this.brokerId,
            selectedBrokerName: this.broker,
            dialog: false,
            selectedLogin: 0,
            timer: null,
            sortBy: "time",
            sortDesc: true,
            monitorChartAsc: true,
            recordDate: common.getMT4TimeString().split(" ")[0],
            chartOption: ["Volume", "PNL"],
            selectedChartOption: "Volume",
            amountOption: ["All", "Top 10"],
            selectedAmount: "Top 10",
            configWarning: false,
        };
    },
    computed: {
        ...mapState("Mandate", [
            "alertHeader",
            "alertData",
            "monitorData",
            "monitorHeader",
            "loading",
            "xData",
            "yData",
            "currentBook",
        ]),
        ...mapState("Alert", ["books", "selectedProfile"]),
        filteredBookOptions() {
            if (!!this.selectedProfile.unselectedBooks) {
                let filtered = [];
                this.books.map((data) => {
                    this.selectedProfile.unselectedBooks;
                    if (!this.selectedProfile.unselectedBooks.includes(data)) {
                        filtered.push(data);
                    }
                });
                return filtered;
            }
            return this.books;
        },
    },
    watch: {
        filteredBookOptions(nv) {
            if (nv.includes("B")) {
                this.UPDATE_CURRENT_BOOK("B");
            } else {
                this.UPDATE_CURRENT_BOOK(nv[0]);
            }
        },
        showing(nv) {
            if (nv) {
                getConfig("mandate").then(({ data }) => {
                    if (data.length === 0) {
                        this.configWarning = true;
                    }
                });
            }
        },
    },
    methods: {
        ...mapActions("Mandate", ["getMandateConfigAction"]),
        ...mapMutations("Mandate", ["UPDATE_CURRENT_BOOK"]),
        ...mapActions("Config", ["getConfigForAlertAction"]),
        /**
         * Open date picker modal
         * @return  {[type]}  [return description]
         */
        toggleDateFilter() {
            this.visible = true;
        },
        /**
         * Change tabs
         * @param   {[type]}  loadModules  [loadModules description]
         * @return  {[type]}               [return description]
         */
        changeTab(loadModules) {
            this.showAlert = false;
            this.showMonitor = false;
            if (loadModules == 1) this.showMonitor = true;
            if (loadModules == 2) this.showAlert = true;
        },
        /**
         * Remove string prefix
         *
         * @param   {[type]}  name  [name description]
         * @return  {[type]}        [return description]
         */
        removePrefix(name) {
            return name.split(":")[1];
        },
        /**
         * Open dialog window
         * @param   {[type]}  login  [login description]
         * @return  {[type]}         [return description]
         */
        loadDialog(user) {
            let login = user.split(":")[1];
            this.selectedLogin = login;
            this.dialog = false;
            this.$nextTick(() => {
                this.dialog = true;
            });
        },
        /**
         * set the selected book name and update the bookName to store
         * @param   {[type]}  bookName  [the name of the selectedBook]
         */
        onSelectBook(bookName) {
            this.UPDATE_CURRENT_BOOK(bookName);
        },
        /**
         * change the sorting order of the chart data
         */
        toggleMonitorSortingOrder() {
            this.monitorChartAsc = !this.monitorChartAsc;
        },
    },
};
</script>

<style scoped>
.v-list-item--dense,
.v-list--dense .v-list-item {
    min-height: 30px;
}
</style>
