<template>
    <div>
        <v-card outlined v-show="showMonitor" class="v-card-border-none">
            <v-card-title class="pt-2 pb-2 v-card-custom-title">
                <div
                    class="text-left v-card-custom-title-left"
                    style="width: 70%"
                >
                    <template v-if="!VUETIFY_BREAKPOINT.mobile">
                        <span style="white-space: nowrap"
                            >Large Exposure CCY Monitor<v-tooltip
                                right
                                v-if="configWarning"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        color="red"
                                        small
                                        style="margin-left: 2px"
                                        >mdi-information-outline</v-icon
                                    >
                                </template>
                                <span
                                    >No config for Large Exposure CCY Monitor
                                    can be found.</span
                                >
                            </v-tooltip></span
                        >
                    </template>
                </div>
                <div
                    class="text-right v-card-custom-title-right"
                    style="width: 30%"
                >
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                x-small
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                class="alertBookSelection"
                            >
                                {{ currentBook }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="(item, index) in filteredBookOptions"
                                :key="index"
                                dense
                                @click="onSelectBook(item)"
                            >
                                <v-list-item-title>{{
                                    item
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <template v-if="!VUETIFY_BREAKPOINT.mobile">
                        {{ " " }}
                        <v-icon
                            text
                            small
                            color="blue-grey darken-1"
                            v-on:click="
                                getConfigForAlertAction('Large Exposure')
                            "
                            v-if="showConfig"
                            class="alertConfig"
                            >mdi-cog</v-icon
                        >
                        {{ " " }}
                        <v-icon
                            small
                            style="top: 2px; color: grey"
                            @click="$emit('onRemove')"
                            class="alertClose"
                            >mdi-close-thick</v-icon
                        >
                    </template>
                </div>
            </v-card-title>

            <ChartLargeExposure :selectedBook="currentBook" :height="height" />
        </v-card>
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import LoginSummaryDialog from "@components/login-summary/ComponentLoginSummaryDialog";
import { helper } from "@components/mixins/helper";
import ChartLargeExposure from "./chart/ComponentLargeExposureChart";
import common from "@assets/js/common";
import { getConfig } from "@services/alert/config";

export default {
    mixins: [helper],
    components: { LoginSummaryDialog, ChartLargeExposure },
    props: {
        height: 0,
        showConfig: false,
    },
    data() {
        return {
            visible: false,
            showAlert: false,
            showMonitor: true,
            selectedBrokerId: this.brokerId,
            selectedBrokerName: this.broker,
            dialog: false,
            selectedLogin: 0,
            timer: null,
            sortBy: "time",
            sortDesc: true,
            recordDate: common.getMT4TimeString().split(" ")[0],
            configWarning: false,
        };
    },
    computed: {
        ...mapState("LargeExposure", [
            "alertHeader",
            "alertData",
            "monitorData",
            "monitorHeader",
            "loading",
            "currentBook",
        ]),
        ...mapState("Alert", ["books", "selectedProfile"]),
        sortedMonitorData() {
            return this.monitorData.sort((a, b) =>
                Math.abs(a.exposure) > Math.abs(b.exposure) ? 1 : -1
            );
        },

        filteredBookOptions() {
            if (!!this.selectedProfile.unselectedBooks) {
                let filtered = [];
                this.books.map((data) => {
                    this.selectedProfile.unselectedBooks;
                    if (!this.selectedProfile.unselectedBooks.includes(data)) {
                        filtered.push(data);
                    }
                });
                return filtered;
            }
            return this.books;
        },
    },
    watch: {
        showing(nv) {
            if (nv) {
                getConfig("large-exposure").then(({ data }) => {
                    if (data.length === 0) {
                        this.configWarning = true;
                    }
                });
            }
        },
        filteredBookOptions(nv) {
            if (nv.includes("B")) {
                this.UPDATE_CURRENT_BOOK("B");
            } else {
                this.UPDATE_CURRENT_BOOK(nv[0]);
            }
        },
        selectedProfile() {
            this.UPDATE_MONITOR_DATA([]);
        },
    },
    methods: {
        ...mapMutations("LargeExposure", [
            "UPDATE_MONITOR_DATA",
            "UPDATE_CURRENT_BOOK",
        ]),
        ...mapActions("Config", ["getConfigForAlertAction"]),
        onSelectBook(book) {
            this.UPDATE_CURRENT_BOOK(book);
        },
    },
    created() {
        
    },
};
</script>

<style scoped>
.v-list-item--dense,
.v-list--dense .v-list-item {
    min-height: 30px;
}
</style>
