<template>
    <div>
        <v-card outlined v-show="showAlert" class="v-card-border-none">
            <v-card-title class="pt-2 pb-2 v-card-custom-title">
                <div class="text-left v-card-custom-title-left">
                    <template v-if="!VUETIFY_BREAKPOINT.mobile">
                        <span style="white-space: nowrap"
                            >Big Lot Alert<v-tooltip right v-if="configWarning">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        color="red"
                                        small
                                        style="margin-left: 2px"
                                        >mdi-information-outline</v-icon
                                    >
                                </template>
                                <span
                                    >No config for Big Lot Alert can be
                                    found.</span
                                >
                            </v-tooltip></span
                        >
                    </template>
                </div>
                <div class="text-right v-card-custom-title-right">
                    <v-btn
                        icon
                        x-small
                        :loading="loading"
                        color="primary"
                        v-on:click="refreshData()"
                        style="margin-right: 2px"
                        class="alertRefresh"
                        ><v-icon>mdi-refresh</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        x-small
                        color="#d35400"
                        v-on:click="toggleDateFilter()"
                        style="margin-right: 2px"
                        class="alertCalender"
                        ><v-icon>mdi-calendar</v-icon>
                    </v-btn>
                    <template v-if="!VUETIFY_BREAKPOINT.mobile">
                        <download-excel
                            :data="filteredAlertData"
                            :fields="csvHeader"
                            style="display: inline"
                            type="csv"
                            name="Big_Lot.csv"
                            :escapeCsv="false"
                        >
                            <v-btn
                                icon
                                x-small
                                :disabled="filteredAlertData.length === 0"
                                color="green"
                                style="margin-right: 2px"
                                class="alertDownload"
                                ><v-icon>mdi-microsoft-excel</v-icon>
                            </v-btn>
                        </download-excel>
                        <v-btn
                            icon
                            x-small
                            color="blue-grey darken-1"
                            v-on:click="getConfigForAlertAction('Large Volume')"
                            style="margin-right: 2px"
                            v-if="showConfig"
                            class="alertConfig"
                            ><v-icon>mdi-cog</v-icon>
                        </v-btn>
                        {{ " " }}
                        <v-icon
                            small
                            style="color: grey"
                            @click="$emit('onRemove')"
                            class="alertClose"
                            >mdi-close-thick</v-icon
                        >
                    </template>
                </div>
            </v-card-title>

            <v-data-table
                :style="'max-width:' + 950 + isIBRequired ? 280 : 0 + 'px'"
                dense
                item-key="name"
                :customSort="(item) => item"
                :items="filteredAlertData"
                :headers="ibDeterminedHeader"
                class="elevation-0 alert-datatable"
                :items-per-page.sync="offset"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :height="height - 120"
                fixed-header
                :header-props="{ sortIcon: null }"
                :page.sync="currentPage"
                :server-items-length="alertDataAmount"
                :mobile-breakpoint="0"
                :footer-props="{
                    pagination: {
                        page: currentPage,
                        itemsPerPage: offset,
                        pageStart: (currentPage - 1) * offset,
                        pageStop: currentPage * offset,
                        pageCount: Math.ceil(alertDataAmount / offset),
                        itemsLength: alertDataAmount,
                    },
                    'items-per-page-text': '',
                    'items-per-page-options': [30, 50, 100, 300, 500, 1000],
                }"
            >
                <template v-slot:item="{ item }">
                    <tr @mouseover="updateRow(item)">
                        <td column="IB" align="left" v-if="isIBRequired">
                            {{ item.partner_id }}
                        </td>
                        <td
                            column="Back Office"
                            align="left"
                            v-if="isIBRequired"
                        >
                            {{ item.back_office_id }}
                        </td>
                        <td column="Customer" align="left" v-if="isIBRequired">
                            {{ item.account_id }}
                        </td>
                        <td column="login">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <a
                                        v-bind="attrs"
                                        v-on="on"
                                        v-on:click="loadDialog(item)"
                                        v-if="showHomeLoginDetailSummary"
                                        style="
                                            display: inline-block;
                                            height: 21px;
                                        "
                                        >{{
                                            item.server_name + ":" + item.login
                                        }}</a
                                    >

                                    <div v-bind="attrs" v-on="on" v-else>
                                        {{
                                            item.server_name + ":" + item.login
                                        }}
                                    </div>
                                </template>
                                <span
                                    v-html="extractAlertInfo(item)"
                                ></span> </v-tooltip
                            ><v-icon v-show="item.new == true"
                                >mdi-new-box</v-icon
                            >
                        </td>
                        <td
                            column="name"
                            align="left"
                            v-if="
                                selectedProfile.settings['largeVolume'].name
                                    .showing
                            "
                        >
                            {{ item.name }}
                        </td>
                        <td
                            column="symbol"
                            v-if="
                                selectedProfile.settings['largeVolume'].symbol
                                    .showing
                            "
                        >
                            {{ item.symbol }}
                        </td>
                        <td
                            column="books"
                            v-if="
                                selectedProfile.settings['largeVolume'].books
                                    .showing
                            "
                        >
                            {{ item.books }}
                        </td>
                        <td
                            column="volume"
                            align="right"
                            v-if="
                                selectedProfile.settings['largeVolume'].volume
                                    .showing
                            "
                        >
                            {{
                                item.direction == "SHORT"
                                    ? item.volume * -1
                                    : item.volume
                            }}
                        </td>

                        <td
                            column="open_time"
                            v-if="
                                selectedProfile.settings['largeVolume']
                                    .open_time.showing
                            "
                        >
                            {{ item.open_time }}
                        </td>

                        <td
                            column="close_time"
                            v-if="
                                selectedProfile.settings['largeVolume']
                                    .close_time.showing
                            "
                        >
                            {{ item.close_time }}
                        </td>
                        <td
                            column="action"
                            v-if="
                                selectedProfile.settings['largeVolume'].action
                                    .showing
                            "
                        >
                            {{ item.action }}
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="visible" width="400" :hide-overlay="false">
            <v-card>
                <v-card-title class="text-h5">Filter Date</v-card-title>
                <v-card-text
                    >Choose date to filter history alerts data.</v-card-text
                >
                <v-container>
                    <v-row>
                        <v-col
                            lg="12"
                            md="12"
                            xs="12"
                            cols="12"
                            style="text-align: center"
                        >
                            <v-date-picker v-model="recordDate"></v-date-picker>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text v-on:click="refreshData()"
                        >Ok</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <LoginSummaryDialog
            v-if="dialog"
            :fullLogin="fullLoginValue"
            :login="loginValue"
            :server="serverValue"
            :symbol="symbolValue"
        />
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import LoginSummaryDialog from "@components/login-summary/ComponentLoginSummaryDialog";
import { helper } from "@components/mixins/helper";
import common from "@assets/js/common";
import deepClone from "deep-clone";
import { permission } from "@components/mixins/permission";
import { getConfig } from "@services/alert/config";

export default {
    mixins: [helper, permission],
    components: { LoginSummaryDialog },
    props: {
        height: 0,
        showing: false,
        showConfig: false,
    },
    data() {
        return {
            timeout: null,

            visible: false,
            showAlert: true,
            showMonitor: false,
            dialog: false,
            sortBy: undefined,
            sortDesc: undefined,
            recordDate: common.getMT4TimeString().split(" ")[0],
            showHomeLoginDetailSummary: false,
            alertInterval: null,
            fullLoginValue: null,
            loginValue: null,
            serverValue: null,
            symbolValue: null,
            offset: 30,
            currentPage: 1,
            configWarning: false,
            isIBRequired: false,
        };
    },
    computed: {
        ...mapState("LargeVolume", [
            "alertHeader",
            "alertData",
            "monitorData",
            "monitorHeader",
            "loading",
            "csvHeader",
            "alertDataAmount",
        ]),
        ...mapState("Alert", [
            "selectedProfile",
            "selectedPeriod",
            "books",
            "extraIBHeaders",
        ]),
        ...mapState("SettingsServer", ["serverData"]),
        filteredAlertData() {
            if (!!this.selectedProfile.unselectedBooks) {
                if (this.selectedProfile.unselectedBooks.length === 0) {
                    return this.alertData;
                } else {
                    let filtered = [];
                    this.alertData.map((data) => {
                        if (
                            !(
                                data.books.split(",").length == 1 &&
                                this.selectedProfile.unselectedBooks.includes(
                                    data.books
                                )
                            )
                        ) {
                            filtered.push(data);
                        }
                    });
                    return filtered;
                }
            }
            return this.alertData;
        },
        ibDeterminedHeader() {
            if (this.isIBRequired) {
                return [...this.extraIBHeaders, ...this.alertHeader];
            } else {
                return this.alertHeader;
            }
        },
    },
    watch: {
        selectedProfile(nv) {
            const settings = nv.settings["largeVolume"];
            const columnName = Object.keys(settings);
            let newHeader = deepClone(this.alertHeader);
            newHeader.map((item) => {
                if (columnName.includes(item.value)) {
                    item.align = settings[item.value].showing
                        ? ["volume"].includes(item.value)
                            ? "right"
                            : "left"
                        : " d-none";
                }
            });
            this.UPDATE_ALERT_HEADER(newHeader);
        },
        showing(nv) {
            if (nv) {
                getConfig("large-volume").then(({ data }) => {
                    if (data.length === 0) {
                        this.configWarning = true;
                    }
                });
                this.getAlertData();

                if (this.selectedPeriod > 1) {
                    this.alertInterval = setInterval(() => {
                        this.getAlertData();
                    }, this.selectedPeriod * 1000);
                }
            } else {
                clearInterval(this.alertInterval);
            }
        },
        selectedPeriod(nv) {
            if (this.showing) {
                clearInterval(this.alertInterval);
                if (nv !== 0) {
                    this.alertInterval = setInterval(() => {
                        this.getAlertData();
                    }, nv * 1000);
                }
            }
        },
        sortBy() {
            this.getAlertData();
        },
        sortDesc() {
            this.getAlertData();
        },
        offset() {
            this.getAlertData();
        },
        currentPage() {
            this.getAlertData();
        },
    },
    methods: {
        ...mapMutations("LargeVolume", [
            "UPDATE_ALERT_HEADER",
            "UPDATE_ALERT_DATA",
            "UPDATE_SELECTED_DATE",
        ]),
        ...mapActions("Config", ["getConfigForAlertAction"]),

        ...mapActions("LargeVolume", ["getDataLargeVolumeAction"]),
        /**
         * Open date picker modal
         * @return  {[type]}  [return description]
         */
        toggleDateFilter() {
            this.visible = true;
        },
        /**
         * Refresh alert data
         * @return  {[type]}  [return description]
         */
        refreshData() {
            this.visible = false;
            this.getAlertData();
        },

        /**
         * Get alet data
         * @return  {[type]}  [return description]
         */
        getAlertData() {
            this.timeout && clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.UPDATE_SELECTED_DATE(this.recordDate);
                const selectedBooks = this.books.filter(
                    (element) =>
                        !this.selectedProfile.unselectedBooks.includes(element)
                );

                let params = {
                    start_time: this.recordDate + " 00:00:00",
                    end_time: this.recordDate + " 23:59:59",
                    offset: this.offset,
                    page: this.currentPage,
                    sort_by:
                        typeof this.sortBy === "object"
                            ? this.sortBy[0]
                            : this.sortBy,
                    sortDesc:
                        typeof this.sortDesc === "object"
                            ? this.sortDesc[0]
                            : this.sortDesc,
                    selected_book: selectedBooks.join(","),
                    ib_required: this.isIBRequired,
                };
                this.getDataLargeVolumeAction(params);
            }, 100);
        },
        /**
         * Change tabs
         * @param   {[type]}  loadModules  [loadModules description]
         * @return  {[type]}               [return description]
         */
        changeTab(loadModules) {
            this.showAlert = false;
            this.showMonitor = false;
            if (loadModules == 1) this.showMonitor = true;
            if (loadModules == 2) this.showAlert = true;
        },
        /**
         * Remove string prefix
         *
         * @param   {[type]}  name  [name description]
         * @return  {[type]}        [return description]
         */
        removePrefix(name) {
            return name.split(":")[1];
        },
        loadDialog(item) {
            const broker = this.serverData.find(
                (serverInfo) => serverInfo.server_name === item.server_name
            )?.broker_name;
            if (!!broker) {
                this.fullLoginValue =
                    broker + ":" + item.server_name + ":" + item.login;
                this.loginValue = item.login.toString();
                this.serverValue = item.server_name;
                this.symbolValue = item.symbol.split(".")[0];
                this.dialog = false;
                this.$nextTick(() => {
                    this.dialog = true;
                });
            }
        },
        extractAlertInfo(data) {
            let result = "";

            result +=
                "<p class='mb-2'>Server Name: " + data.server_name + "</p>";
            result +=
                "<p class='mb-2'>Order: " + data.order.toString() + "</p>";
            result +=
                "<p class='mb-2'>Volume Threshold: " +
                data.threshold_volume.toString() +
                "</p>";
            result +=
                "<p class='mb-2'>Trigger Time: " + data.trigger_time + "</p>";

            return result;
        },
        updateRow(row) {
            if (row.new) {
                const index = this.alertData.indexOf(row);
                this.alertData[index].new = false;
                this.UPDATE_ALERT_DATA(this.alertData);
            }
        },
    },
    created() {
        const userFunction = this.getFunction("book");
        const alertChild = JSON.parse(localStorage.getItem("permission"))
            .frontPermission.alert.child;

        if (userFunction.includes("homeLoginDetailSummary")) {
            this.showHomeLoginDetailSummary = true;
        }
        if (alertChild.includes("ibRelatedFieldsAlert")) {
            this.isIBRequired = true;
        }
    },
    mounted() {
        if (!!this.VUETIFY_BREAKPOINT.mobile) {
            this.getAlertData();
        }
    },
    destroyed() {
        clearInterval(this.alertInterval);
    },
};
</script>
